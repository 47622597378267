import { isPast, isToday, isTomorrow } from "date-fns";
import { cn } from "lib/utils";
import {
  CalendarCheck,
  CheckIcon,
  ClockIcon,
  EllipsisVerticalIcon,
  GripIcon,
  Paintbrush,
  PencilIcon,
  Trash,
} from "lucide-react";
import { useState } from "react";
import LinkParser from "react-link-parser";
import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { Card } from "./ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Textarea } from "./ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

const colors = [
  ["bg-gray-500", "Neutral"],
  ["bg-red-500", "Critical"],
  ["bg-orange-500", "High Priority"],
  ["bg-yellow-500", "Important"],
  ["bg-lime-500", "Low Priority"],
  ["bg-teal-500", "In Progress"],
  ["bg-blue-500", "Info"],
  ["bg-purple-500", "Review"],
  ["bg-pink-500", "Idea"],
];

export function Task(props: any) {
  const { data: task } = props;
  const [content, setContent] = useState(task.content);

  const dueDate = task.dueDate ? new Date(task.dueDate) : undefined;

  return (
    <TooltipProvider delayDuration={200}>
      <Card
        className={cn(
          "flex items-center justify-between px-4 py-2 gap-2 group",
          task.completed && "line-through opacity-60"
        )}
      >
        {!task.completed && (
          <div {...props.dragHandleProps}>
            <GripIcon className="w-4 h-4 " />
          </div>
        )}
        <div className="flex-1 [&>a]:text-blue-500 [&>a:hover]:underline">
          {task.labels?.length > 0 && (
            <div className="flex gap-1 mb-2">
              {task.labels?.map(([color, label]: [string, string]) => (
                <Tooltip key={color}>
                  <TooltipTrigger>
                    <span
                      className={cn(
                        "block w-5 h-2 rounded-full cursor-help",
                        color
                      )}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{label}</p>
                  </TooltipContent>
                </Tooltip>
              ))}
            </div>
          )}

          <LinkParser>{task.content}</LinkParser>

          {dueDate && (
            <div className="mt-2">
              <Badge
                className={cn(isTomorrow(dueDate) && "bg-yellow-200")}
                variant={
                  isToday(dueDate) || isPast(dueDate)
                    ? "destructive"
                    : "secondary"
                }
              >
                <ClockIcon className="w-4 h-4 mr-2" />
                {dueDate.toDateString()}
              </Badge>
            </div>
          )}
        </div>
        <div className="flex gap-1 opacity-0 transition-all duration-[50ms] group-hover:opacity-100 group-focus-within:opacity-100">
          {!task.completed && (
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="ghost" size="icon">
                  <PencilIcon className="w-4 h-4" />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Edit task</DialogTitle>
                </DialogHeader>

                <div>
                  <Textarea
                    placeholder="Edit task"
                    defaultValue={task.content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </div>

                <DialogFooter>
                  <DialogClose asChild>
                    <Button
                      type="submit"
                      onClick={() => props.onClickEdit(content)}
                    >
                      Save changes
                    </Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
          <Button variant="ghost" size="icon" onClick={props.onClickFinish}>
            <CheckIcon className="w-4 h-4 " />
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <EllipsisVerticalIcon className="w-4 h-4 " />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={props.onClickDelete}>
                <Trash className="mr-3 h-4 w-4" />
                <span>Delete</span>
              </DropdownMenuItem>
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  <CalendarCheck className="mr-3 h-4 w-4" />
                  <span>Set due date</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <Calendar
                      mode="single"
                      disabled={{ before: new Date() }}
                      selected={dueDate}
                      onSelect={props.onSelectDueDate}
                      numberOfMonths={1}
                      defaultMonth={dueDate}
                    />
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  <Paintbrush className="mr-3 h-4 w-4" />
                  <span>Set labels</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <div className="grid grid-cols-3 gap-1 max-w-[200px]">
                      {colors.map(([color, name]) => (
                        <Tooltip key={color}>
                          <TooltipTrigger asChild>
                            <Button
                              variant={
                                task.labels?.some((l: any) => l[0] === color)
                                  ? "secondary"
                                  : "ghost"
                              }
                              onClick={() =>
                                props.onClickSetLabel([color, name])
                              }
                            >
                              <span
                                className={cn("w-4 h-4 rounded-full", color)}
                              ></span>
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{name}</p>
                          </TooltipContent>
                        </Tooltip>
                      ))}
                    </div>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </Card>
    </TooltipProvider>
  );
}
