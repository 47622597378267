import { Quadrant } from "components/quadrant";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext } from "@hello-pangea/dnd";

function App() {
  const [state, setState] = useState(
    JSON.parse(
      localStorage.getItem("state") ||
        `{"urgent": [], "not_urgent": [], "delegate": [], "delete": []}`
    )
  );

  const onNewTask = (quadrant: string) => (e: any) => {
    e.preventDefault();
    const content = e.target[0].value;
    if (!content) return;
    setState((state: any) => ({
      ...state,
      [quadrant]: [
        {
          content,
          completed: false,
          id: Date.now(),
          dueDate: null,
          labels: [],
        },
        ...state[quadrant],
      ],
    }));
    e.target.reset();
  };

  const onDragEnd = useCallback(
    (params: any) => {
      const { source, destination } = params;
      if (!destination) return;

      const sourceId = source.droppableId;
      const destinationId = destination.droppableId;
      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      if (sourceId === destinationId) {
        // Reorder within the same quadrant
        const tasks = [...state[sourceId]];
        const [removed] = tasks.splice(sourceIndex, 1);
        tasks.splice(destinationIndex, 0, removed);
        setState((state: any) => ({
          ...state,
          [sourceId]: tasks,
        }));
      } else {
        // Move to a different quadrant and reoder there
        const sourceTasks = [...state[sourceId]];
        const destinationTasks = [...state[destinationId]];
        const [removed] = sourceTasks.splice(sourceIndex, 1);
        destinationTasks.splice(destinationIndex, 0, removed);
        setState((state: any) => ({
          ...state,
          [sourceId]: sourceTasks,
          [destinationId]: destinationTasks,
        }));
      }
    },
    [state]
  );

  const onClickFinish = (quadrant: string) => (task: any) => () => {
    setState((state: any) => {
      const tasks = state[quadrant].map((t: any) =>
        t.id === task.id ? { ...t, completed: !t.completed } : t
      );
      const completedTasks = tasks.filter((t: any) => t.completed);
      const uncompletedTasks = tasks.filter((t: any) => !t.completed);
      return {
        ...state,
        [quadrant]: [...uncompletedTasks, ...completedTasks],
      };
    });
  };

  const onClickDelete = (quadrant: string) => (task: any) => () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this task?"
    );
    if (!confirm) return;
    setState((state: any) => ({
      ...state,
      [quadrant]: state[quadrant].filter((t: any) => t.id !== task.id),
    }));
  };

  const onClickClearTasks = (quadrant: string) => () => {
    const confirm = window.confirm(
      "Are you sure you want to clear all completed tasks?"
    );

    if (confirm) {
      setState((state: any) => ({
        ...state,
        [quadrant]: state[quadrant].filter((t: any) => !t.completed),
      }));
    }
  };

  const onClickEdit = (quadrant: string) => (task: any, newContent: string) => {
    if (!newContent) return;

    setState((state: any) => ({
      ...state,
      [quadrant]: state[quadrant].map((t: any) =>
        t.id === task.id ? { ...t, content: newContent.trim() } : t
      ),
    }));
  };

  const onSelectDueDate = (quadrant: string) => (task: any) => (date: Date) => {
    setState((state: any) => ({
      ...state,
      [quadrant]: state[quadrant].map((t: any) =>
        t.id === task.id ? { ...t, dueDate: date } : t
      ),
    }));
  };

  const onClickSetLabel =
    (quadrant: string) => (task: any) => (label: [string, string]) => {
      setState((state: any) => ({
        ...state,
        [quadrant]: state[quadrant].map((t: any) =>
          t.id === task.id
            ? {
                ...t,
                labels: t.labels?.some(
                  (l: [string, string]) => l[0] === label[0]
                )
                  ? t.labels.filter((l: [string, string]) => l[0] !== label[0])
                  : [...(t?.labels ?? []), label],
              }
            : t
        ),
      }));
    };

  useEffect(() => {
    localStorage.setItem("state", JSON.stringify(state));
  }, [state]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex flex-col h-screen">
        <div className="grid grid-cols-2 grid-rows-2 flex-1 max-h-full">
          <Quadrant
            className="bg-blue-50"
            title="Important / Urgent"
            id="urgent"
            tasks={state.urgent}
            onNewTask={onNewTask("urgent")}
            onClickFinish={onClickFinish("urgent")}
            onClickDelete={onClickDelete("urgent")}
            onClickClearTasks={onClickClearTasks("urgent")}
            onClickEdit={onClickEdit("urgent")}
            onSelectDueDate={onSelectDueDate("urgent")}
            onClickSetLabel={onClickSetLabel("urgent")}
          />
          <Quadrant
            className="bg-green-50"
            title="Important / Not Urgent"
            id="not_urgent"
            tasks={state.not_urgent}
            onNewTask={onNewTask("not_urgent")}
            onClickFinish={onClickFinish("not_urgent")}
            onClickDelete={onClickDelete("not_urgent")}
            onClickClearTasks={onClickClearTasks("not_urgent")}
            onClickEdit={onClickEdit("not_urgent")}
            onSelectDueDate={onSelectDueDate("not_urgent")}
            onClickSetLabel={onClickSetLabel("not_urgent")}
          />
          <Quadrant
            className="bg-orange-50"
            title="Not Important / Delegate"
            id="delegate"
            tasks={state.delegate}
            onNewTask={onNewTask("delegate")}
            onClickFinish={onClickFinish("delegate")}
            onClickDelete={onClickDelete("delegate")}
            onClickClearTasks={onClickClearTasks("delegate")}
            onClickEdit={onClickEdit("delegate")}
            onSelectDueDate={onSelectDueDate("delegate")}
            onClickSetLabel={onClickSetLabel("delegate")}
          />
          <Quadrant
            className="bg-red-50"
            title="Not Important / Delete"
            id="delete"
            tasks={state.delete}
            onNewTask={onNewTask("delete")}
            onClickFinish={onClickFinish("delete")}
            onClickDelete={onClickDelete("delete")}
            onClickClearTasks={onClickClearTasks("delete")}
            onClickEdit={onClickEdit("delete")}
            onSelectDueDate={onSelectDueDate("delete")}
            onClickSetLabel={onClickSetLabel("delete")}
          />
        </div>
      </div>
    </DragDropContext>
  );
}

export default App;
