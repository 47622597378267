import { Draggable, Droppable } from "@hello-pangea/dnd";
import { cn } from "lib/utils";
import { ChevronsUpDownIcon, PlusIcon } from "lucide-react";
import { Task } from "./task";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./ui/collapsible";
import { Input } from "./ui/input";
import { Button } from "./ui/button";

export function Quadrant(props: any) {
  const completed = props.tasks.filter((task: any) => task.completed);
  const notCompleted = props.tasks.filter((task: any) => !task.completed);

  return (
    <div
      className={cn(
        "bg-gray-100 dark:bg-gray-900 p-6 flex flex-col gap-4",
        props.className
      )}
    >
      <div className="flex items-center justify-between gap-4">
        <h2 className="text-xl font-bold">{props.title}</h2>
        <form onSubmit={props.onNewTask} className="flex gap-2 flex-1">
          <Input placeholder="Add new task" className="w-full" />
          <Button type="submit" size="icon" variant="ghost">
            <PlusIcon className="w-4 h-4 text-gray-500" />
          </Button>
        </form>
        <Button
          variant="outline"
          title="Remove all completed tasks"
          onClick={props.onClickClearTasks}
          disabled={completed.length === 0}
        >
          Clear
        </Button>
      </div>
      <Droppable droppableId={props.id}>
        {(provided, snapshot) => (
          <div
            className={cn(
              "flex flex-col gap-2 overflow-y-scroll h-full rounded-sm",
              snapshot.isDraggingOver &&
                "bg-slate-300 shadow-slate-300 shadow-[0_0_0_8px]"
            )}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {notCompleted.map((task: any, index: number) => (
              <Draggable
                draggableId={String(task.id)}
                key={task.id}
                index={index}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <Task
                      data={task}
                      onSelectDueDate={props.onSelectDueDate(task)}
                      onClickFinish={props.onClickFinish(task)}
                      onClickDelete={props.onClickDelete(task)}
                      onClickEdit={(newContent: string) =>
                        props.onClickEdit(task, newContent)
                      }
                      onClickSetLabel={props.onClickSetLabel(task)}
                      dragHandleProps={provided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}

            {completed.length > 0 && notCompleted.length > 0 && (
              <hr className="my-4" />
            )}

            {completed.slice(0, 2).map((task: any) => (
              <Task
                key={task.id}
                data={task}
                onSelectDueDate={props.onSelectDueDate(task)}
                onClickFinish={props.onClickFinish(task)}
                onClickDelete={props.onClickDelete(task)}
                onClickSetLabel={props.onClickSetLabel(task)}
              />
            ))}

            {completed.slice(2).length > 0 && (
              <Collapsible>
                <CollapsibleTrigger className="gap-2 text-sm flex items-center text-gray-500 mb-2">
                  You have {completed.slice(2).length} more completed tasks.
                  <ChevronsUpDownIcon className="w-4 h-4 text-gray-500" />
                </CollapsibleTrigger>

                <CollapsibleContent>
                  <div className="flex flex-col gap-2">
                    {completed.slice(2).map((task: any) => (
                      <Task
                        key={task.id}
                        data={task}
                        onSelectDueDate={props.onSelectDueDate(task)}
                        onClickFinish={props.onClickFinish(task)}
                        onClickDelete={props.onClickDelete(task)}
                        onClickSetLabel={props.onClickSetLabel(task)}
                      />
                    ))}
                  </div>
                </CollapsibleContent>
              </Collapsible>
            )}
          </div>
        )}
      </Droppable>
    </div>
  );
}
